
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import Banner from "./pages/Home/Banner"; 
import AboutUs from "./pages/AboutUs/AboutUs";  
import Course from "./pages/Course/Course";
import Facilities from "./pages/Facilities/Facilities";
import Gallery from "./pages/Gallery/Gallery";
import ContactUs from "./pages/ContactUs/ContactUs"; 

import Computer from "./pages/Facilities/Computer";
import Educational from "./pages/Facilities/Educational";
import Psychology from "./pages/Facilities/Psychology";
import Biological from "./pages/Facilities/Biological";
import Language from "./pages/Facilities/Language"; 
import ScienceLab from "./pages/Facilities/ScienceLab";


const routes = (
  <Router>    
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/Banner' element={<Banner/>} /> 

      <Route path='/AboutUs' element={<AboutUs/>} /> 
      <Route path='/Course' element={<Course/>} />
      <Route path='/Facilities' element={<Facilities/>} />
      <Route path='/Gallery' element={<Gallery/>} />
      <Route path='/ContactUs' element={<ContactUs/>} />

      <Route path='/Computer' element={<Computer/>} />
      <Route path='/Educational' element={<Educational/>} />
      <Route path='/Psychology' element={<Psychology/>} />
      <Route path='/Biological' element={<Biological/>} />
      <Route path='/ScienceLab' element={<ScienceLab/>} />
      <Route path='/Language' element={<Language/>} />
      

    </Routes>
 </Router> )
function App() {
  return (
    <>
        {routes}  
    </>
  );
}

export default App;
