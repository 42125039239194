import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function Educational() { 
	return( 
	<>  
    
      <Header /> 
       
      <main> 
        <div class="page-title-area section-notch pt-50 pb-50">
            <div class="banner-overlay"></div>
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="page-title">
                            <h2>Facilities</h2>
                            <div class="breadcrumb-list text-left">
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li>Facilities</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="sidebar ttm-sidebar pt-20 pb-20">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 widget-area" id="bar-fixed">
                        <aside class="widget widget-nav-menu">
                            <ul class="widget-menu">
                                <li><a href="/Facilities">Library </a></li>
                                <li><a href="/Computer">Computer Lab</a></li>
                                <li><a class="active" href="/Educational">Educational Technology Lab</a></li>
                                <li><a href="/Psychology">Psychology Lab</a></li>                             
                                <li><a href="/ScienceLab">Science Lab</a></li>
                                <li><a href="/Language">Language Lab</a></li>
                            </ul>
                        </aside>                            
                        <aside class="widget widget-download">
                            <h3 class="widget-title">Download</h3>
                            <ul class="download">
                                <li>
                                    <i class="fa fa-file-pdf-o"></i>
                                    <a href="../assets/images/PSM_College_Application.pdf" title="Download">Application</a>
                                </li> 
                            </ul>
                        </aside>
                    </div>
                    <div class="col-lg-9 content-area" id="Library"> 
                        <div class="ttm-service-single-content-area"> 
                            <div class="section-title with-desc text-left clearfix">
                                <div class="title-header"> 
                                    <h2 class="title">Educational Technology Laboratory</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 ttm_single_image-wrapper mb-35">
                                    <img class="img-fluid" src="../assets/images/educational.jpg" alt="single-img-twelve"/>
                                </div>
                                <div class="col-md-12 ttm-service-description"> 
                                    <p>This centre has LCD and OHP projectors, slide projectors and other equipments to aid the teaching-learning process.</p>
                                    <p>Under the guidance of a qualified Audio-Visual Technician, student teachers make use of the centre for instructional training.</p>
                                    <p>The lab is equipped with hardware and software packages, CDs and audiotapes for learning. Educational technology lab is a place where learners with different needs technological skills background and experiences can work together.</p>
                                </div>
                            </div>    
                        </div> 
                    </div>                    
                </div>
            </div>
        </section> 

    </main>

      <Footer/>

  </> 
  ); 
  } 
  export default Educational;