import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function Course() { 
	return( 
	<>  
    
    <Header /> 
    <main class="rs-blog "> 
        <div class="page-title-area section-notch pt-50 pb-50">
            <div class="banner-overlay"></div>
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="page-title">
                            <h2>Course</h2>
                            <div class="breadcrumb-list text-left">
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li>Course</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cources-section pt-60 pb-60">
                <div class="container custom">
                    <div class="row">
                        {/* <div class="col-lg-4 col-md-12 order-last"> 
                            <div class="course-categories"> 
                                <h5>Theory Courses for First Year </h5> 
                                <ul class="course-categories-list">
                                    <li>
                                        <span class="course-number">Course 1 </span>
                                        <span class="course-name">Childhood and Growing Up</span>
                                    </li>
                                    <li>
                                        <span class="course-number">Course 2 </span>
                                        <span class="course-name">Contemporary India and Education</span>
                                    </li>
                                    <li>
                                        <span class="course-number">Course 3 </span>
                                        <span class="course-name">Learning and Teaching</span>
                                    </li>
                                    <li>
                                        <span class="course-number">Course 4 </span>
                                        <span class="course-name">Language across the Curriculum</span>
                                    </li>
                                    <li>
                                        <span class="course-number">Course 5 </span>
                                        <span class="course-name">Understanding Disciplines & Subjects</span>
                                    </li>
                                    <li>
                                        <span class="course-number">Course 6 </span>
                                        <span class="course-name">Gender, School and Society</span>
                                    </li>
                                    <li>
                                        <span class="course-number">Course 7 </span>
                                        <span class="course-name">Pedagogy of a School subject-Part-I (Methodology)</span>
                                    </li>
                                    <li>
                                        <span class="course-number">Course 7-B </span>
                                        <span class="course-name">Pedagogy of a School subject-Part-II (Content Mastery)</span>
                                    </li>
                                    <li>
                                        <span class="course-number">Course 8 </span>
                                        <span class="course-name">Knowledge and Curriculum</span>
                                    </li>
                                    <li>
                                        <span class="course-number">Course 9 </span>
                                        <span class="course-name">Assessment for Learning</span>
                                    </li>
                                    <li>
                                        <span class="course-number">Course 10 </span>
                                        <span class="course-name">Creating an Inclusive School</span>
                                    </li>
                                    <li>
                                        <span class="course-number">Course 11 </span>
                                        <span class="course-name">Environmental Education</span>
                                    </li> 
                                </ul>
                            </div> 
                            <div class="course-categories"> 
                                <h5>Pedagogy of a School subjects  </h5> 
                                <p>The College offers B.Ed., course for the following nine subjects.</p>
                                <ul class="course-categories-list">
                                    <li>
                                        <span class="course-number">Tamil</span> 
                                    </li>
                                    <li>
                                        <span class="course-number">English</span> 
                                    </li>
                                    <li>
                                        <span class="course-number">Mathematics</span> 
                                    </li>
                                    <li>
                                        <span class="course-number">Physical Science</span> 
                                    </li>
                                    <li>
                                        <span class="course-number">Biological Science</span> 
                                    </li>
                                    <li>
                                        <span class="course-number">History</span> 
                                    </li>
                                    <li>
                                        <span class="course-number">Commerce</span> 
                                    </li>
                                    <li>
                                        <span class="course-number">Computer Science</span> 
                                    </li>
                                    <li>
                                        <span class="course-number">Economics</span> 
                                    </li>
                                    
                                </ul>
                            </div> 
                        </div> */}
                        <div class="col-lg-12 md-pr-15 md-mt-50">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="cources">
                                        {/* <h4>ABOUT THE B.ED., COURSE</h4> */}
                                        <h5>Duration of the B.Ed., Course</h5>
                                        <p>A candidate shall be eligible for admission to B.Ed. programme (in Government /Government Aided/Self-Financing Colleges of Education) leading to the Degree of Bachelor of Education (B.Ed.) provided fulfilling the prescribed education qualification and other requirements as specified in the B.Ed. Admission Guidelines of the Government of Tamil Nadu in force from time to time.</p>
                                        <h5>Duration of the Programme</h5>
                                        <p>The B.Ed. programme shall be for duration of two academic years consisting of four semesters. Each semester is spread over for a period of 100 working days (36 hours in a week spreading over for 5 or 6 working days in a week, excluding the period of examination and admission.</p>
                                        <h5>Curriculum of Programme</h5>
                                        <p>The curriculum of the B.Ed. programme consists of fourteen Compulsory Theory Courses, one Elective Course out of six elective courses and Engagement with the Field (School Internship, Courses on Enhancing Professional Capacities (EPC), and an Online Course).</p>
                                        <h5>Programme Structure and Semester-wise Distribution of Courses</h5>
                                        <h6>Semester-I</h6>
                                        <div class="table-responsive">
                                            <table class="table table-bordered course-table">
                                                <thead align="center" class="bg-dark text-white">
                                                    <tr>
                                                        <th>SI. No. </th>
                                                        <th>Course Name</th>
                                                        <th>Course Detail</th>
                                                    </tr>
                                                </thead>
                                                <tbody align="center">
                                                    <tr>
                                                        <td>1.</td>
                                                        <td>BD1EP</td>
                                                        <td>Educational Psychology</td>
                                                    </tr>   
                                                    <tr>
                                                        <td>2.</td>
                                                        <td>BD1CE</td>
                                                        <td>Contemporary India and Education</td>
                                                    </tr> 
                                                    <tr>
                                                        <td>3.</td>
                                                        <td>BD1TL</td>
                                                        <td>Teaching and Learning</td>
                                                    </tr> 
                                                    <tr>
                                                        <td>4.</td>
                                                        <td>BD1LC</td>
                                                        <td>Language across the Curriculum</td>
                                                    </tr>  
                                                    <tr>
                                                        <td>5.</td>
                                                        <td>Pedagogical Subjects</td>
                                                        <td>Part – I</td>
                                                    </tr>      
                                                </tbody>
                                            </table> 
                                        </div> 
                                        <h6>Semester-II</h6>
                                        <div class="table-responsive">
                                            <table class="table table-bordered course-table">
                                                <thead align="center" class="bg-dark text-white">
                                                    <tr>
                                                        <th>SI. No. </th>
                                                        <th>Course Name</th>
                                                        <th>Course Detail</th>
                                                    </tr>
                                                </thead>
                                                <tbody align="center">
                                                    <tr>
                                                        <td>1.</td>
                                                        <td>BD2UD</td>
                                                        <td>Understanding Disciplines and Subjects</td>
                                                    </tr>   
                                                    <tr>
                                                        <td>2.</td>
                                                        <td>BD2AL</td>
                                                        <td>Assessment for Learning </td>
                                                    </tr> 
                                                    <tr>
                                                        <td>3.</td>
                                                        <td>BD2EE</td>
                                                        <td>Environmental Education </td>
                                                    </tr> 
                                                    <tr>
                                                        <td>4.</td>
                                                        <td>BD2SM </td>
                                                        <td>School Management and Administration</td>
                                                    </tr>  
                                                    <tr>
                                                        <td>4.</td>
                                                        <td>Pedagogical Subjects</td>
                                                        <td>Part – II</td>
                                                    </tr>      
                                                </tbody>
                                            </table> 
                                        </div> 
                                        <h6>Semester-III</h6>
                                        <div class="table-responsive">
                                            <table class="table table-bordered course-table">
                                                <thead align="center" class="bg-dark text-white">
                                                    <tr>
                                                        <th>SI. No. </th>
                                                        <th>Course Name</th>
                                                        <th>Course Detail</th>
                                                    </tr>
                                                </thead>
                                                <tbody align="center">
                                                    <tr>
                                                        <td>1.</td>
                                                        <td>BD3PC </td>
                                                        <td>Field Engagement (School Internship, EPC & Online Course)</td>
                                                    </tr>   
                                                         
                                                </tbody>
                                            </table> 
                                        </div> 
                                        <div class="course-inner-para">
                                            <h5>Part-A: Assessment of Teaching Competency and Records</h5>
                                            <span><i class="fa fa-check-circle bed-color"></i>Observation Record:  <strong>Level - I & II </strong></span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Micro Teaching Record: <strong>Level -  I & II </strong></span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Demonstration Record: <strong>Level - I & II</strong></span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Lesson Plan Record: <strong>Level - I & II </strong></span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Instructional Materials Record: <strong>Level - I & II</strong></span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Test and Measurement Record: <strong>Level -I & II</strong></span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Case Study Record</span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Psychology Practical Record </span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Environmental Education Record</span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Students Portfolios and Reflective Journals </span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Teaching Competency: <strong>Level - I & II</strong></span>

                                        </div>                                        
                                        <div class="course-inner-para course-inner-para-1">
                                            <h5>Part-B: Enhancing Professional Capacities (EPC) - Records</h5>
                                            <span><i class="fa fa-check-circle bed-color"></i>Reading and Reflecting on Texts</span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Drama and Art in Education</span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Critical Understanding of ICT</span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Understanding the Self</span>
                                        </div>
                                        <div class="course-inner-para course-inner-para-1">
                                            <h5>Part-C: Online Course</h5>
                                            <span><i class="fa fa-check-circle bed-color"></i>1.	Online Course-SWAYAM (any one course related to Pedagogy/ Education/ Basic Discipline Skills)</span>
                                        </div>
                                        <h6>Semester-IV</h6>
                                        <div class="table-responsive">
                                            <table class="table table-bordered course-table">
                                                <thead align="center" class="bg-dark text-white">
                                                    <tr>
                                                        <th>SI. No. </th>
                                                        <th>Course Name</th>
                                                        <th>Course Detail</th>
                                                    </tr>
                                                </thead>
                                                <tbody align="center">
                                                    <tr>
                                                        <td>1.</td>
                                                        <td>BD3PC </td>
                                                        <td>Field Engagement (School Internship, EPC & Online Course)</td>
                                                    </tr>  
                                                    <tr>
                                                        <td>2.</td>
                                                        <td>BD4KC</td>
                                                        <td>Knowledge and Curriculum </td>
                                                    </tr>   
                                                    <tr>
                                                        <td>3.</td>
                                                        <td>BD4CI</td>
                                                        <td>Creating an Inclusive School</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4.</td>
                                                        <td>BD4YH</td>
                                                        <td>Yoga, Health and Physical Education</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5.</td>
                                                        <td>Electives (Any One) </td>
                                                        <td>a.	Values and Peace Education <br/>
                                                         b.	Human Rights Education <br/>
                                                         c.	Community Engagement through Work Education <br/>
                                                         d.	Disaster Management <br/>
                                                         e.	Special Education <br/>
                                                         f.	Life Skills Education</td>
                                                    </tr> 
                                                         
                                                </tbody>
                                            </table> 
                                        </div> 

                                        <div class="course-inner-para">
                                            <h5>Pedagogy of a School subjects </h5>
                                            <p>The College offers B.Ed., course for the following nine subjects.</p>
                                            <span><i class="fa fa-check-circle bed-color"></i>Tamil</span>
                                            <span><i class="fa fa-check-circle bed-color"></i>English</span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Mathematics</span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Physical Science</span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Biological Science</span>
                                            <span><i class="fa fa-check-circle bed-color"></i>History</span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Commerce</span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Computer Science</span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Economics</span>
                                            <span><i class="fa fa-check-circle bed-color"></i>Social Science</span>

                                        </div>
                                        <div>
                                            <p>For more details about the course kindly visit <a target="_blank" href="https://www.tnteu.ac.in/">www.tnteu.ac.in</a> </p>
                                        </div>



                                    </div>
                                </div>
                            </div>             
                        </div>   
                    </div>
                </div> 
            </div>
        </main>
    
    
    <Footer/>

  </> 
  ); 
  } 
  export default Course;