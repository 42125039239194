import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function Facilities() { 
	return( 
	<>  
    
      <Header /> 
       
      <main> 
        <div class="page-title-area section-notch pt-50 pb-50">
            <div class="banner-overlay"></div>
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="page-title">
                            <h2>Facilities</h2>
                            <div class="breadcrumb-list text-left">
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li>Facilities</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="sidebar ttm-sidebar pt-20 pb-20">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 widget-area" id="bar-fixed">
                        <aside class="widget widget-nav-menu">
                            <ul class="widget-menu">
                                <li><a class="active" href="/Facilities">Library </a></li>
                                <li><a href="/Computer">Computer Lab</a></li>
                                <li><a href="/Educational">Educational Technology Lab</a></li> 
                                <li><a href="/Psychology">Psychology Lab</a></li>                            
                                <li><a href="/ScienceLab">Science Lab</a></li>
                                <li><a href="/Language">Language Lab</a></li>
                            </ul>
                        </aside>                            
                        <aside class="widget widget-download">
                            <h3 class="widget-title">Download</h3>
                            <ul class="download">
                                <li>
                                    <i class="fa fa-file-pdf-o"></i>
                                    <a href="../assets/images/PSM_College_Application.pdf" title="Download">Application</a>
                                </li> 
                            </ul>
                        </aside>
                    </div>
                    <div class="col-lg-9 content-area" id="Library"> 
                        <div class="ttm-service-single-content-area"> 
                            <div class="section-title with-desc text-left clearfix">
                                <div class="title-header"> 
                                    <h2 class="title">Library</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 ttm_single_image-wrapper mb-35">
                                    <img class="img-fluid" src="../assets/images/library.jpeg" alt="single-img-twelve"/>
                                </div>
                                <div class="col-md-12 ttm-service-description"> 
                                    <p>Library is the heart of Educational Institutions. The main aim of library is to provide right documents to right users at right time. Our library is a vast ocean of knowledge interspersed with different pearls of well stocked books, Educational Journals and CDs. Our library is stocked with 5700 B.Ed books. </p>
                                    <p>To supplement this knowledge our library has a depository of 15 journals, EBSCO and N-List Database of full-text journal with E-Books, Encyclopedia, Dictionaries, Year Books and new pearls in the form of new books are added frequently. Along with this the library offers services of inter library loan to avail the rich reservoir of knowledge form other libraries.</p>
                                </div>
                            </div>
                            <div class="separator">
                                <div class="sep-line mt-15 mb-20"></div>
                            </div>  
                            <div class="additional-facilities"> 
                                <h5>Facilities and Services</h5>
                                <div class="bullet_ul">
                                    <ul> 
                                        <li><a>Book Lending</a></li> 
                                        <li><a>Orientation to New users</a></li> 
                                        <li><a>Reference Service </a></li> 
                                        <li><a>Inter Library Loan Service </a></li> 
                                        <li><a>Current Awareness Service (Display of New Arrivals) </a></li> 
                                        <li><a>Reprographic Facility on demand </a></li> 
                                        <li><a>Electronic Catalogue </a></li> 
                                        <li><a>E-Resources (E-Books) </a></li> 
                                    </ul>
                                </div>
                            </div>  
                        </div> 
                    </div>                    
                </div>
            </div>
        </section> 

    </main>

      <Footer/>

  </> 
  ); 
  } 
  export default Facilities;